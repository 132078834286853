import React, { useState } from "react";
import Asset30 from "../images/Asset 30.png";
import Asset14 from "../images/Asset 14.png";
import axios from "axios";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Modal(props) {
  const [loading, setLoading] = useState(false);
  const [selectedGrindingType, setSelectedGrindingType] = useState(''); // State for grinding type
  const [count, setCount] = useState(1);
  let user_id = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  let modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0,0.8)",
  };

  const dec = () => {
    if (count <= 1) {
      return;
    } else {
      setCount(count - 1);
    }
  };

  const addToCart = async (id) => {
    if (!user_id) {
      navigate('/login');
    } else {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("menu_id", id);
      formData.append("quantity", count);
      formData.append("grinding_type", selectedGrindingType); // Append the selected grinding type

      const url = "https://chakkimart.in/admin/UserApi/addtocart";

      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        setLoading(true);

        const data = await axios.post(url, formData, config);
        console.log(data);
        window.location.reload(false);
        toast.success("Item Added To Cart Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div
        className="modal"
        show
        fade
        style={modalStyle}
        variants={dropIn}
        initial="hidden"
        animate="visible"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-1">
            <div className="modal-header">
              <img
                src={`https://chakkimart.in/admin/${props.image}`}
                alt="img"
                className="modal_image"
              />
              <h3 className="modal-title" id="exampleModalToggleLabel">
                {props.title}
              </h3>
              <button type="button" className="btn-close" onClick={props.hide}></button>
            </div>
            <div className="modal-body">
              <h6 style={{color:"#000" }}>Package Size</h6>
              <span style={{color:"#000" }}>Select one option</span>
              <div className="row mb-3">
              <div className="col text-center" style={{border: "1px solid #000",  backgroundColor: "rgb(229 221 211 / 34%)", margin: "10px", borderRadius: "7px"}}>
                  <div style={{ fontSize: "20px", color:"#000",fontWeight:"600"}}>5k</div>
                  <div style={{ fontSize: "16px", lineHeight: "1.5",color:"#000" }}>₹380</div>
              </div>
              <div className="col text-center" style={{border: "1px solid #000",  backgroundColor: "rgb(229 221 211 / 34%)", margin: "10px", borderRadius: "7px"}}>
                  <div style={{ fontSize: "20px",color:"#000",fontWeight:"600" }}>2 kg</div>
                  <div style={{ fontSize: "16px", lineHeight: "1.5",color:"#000" }}>₹154</div>
              </div>
              <div className="col text-center" style={{border: "1px solid #000", backgroundColor: "rgb(229 221 211 / 34%)", borderRadius: "7px", margin: "10px"}}>
                  <div style={{ fontSize: "20px",color:"#000",fontWeight:"600"}}>1 kg</div>
                  <div style={{ fontSize: "16px", lineHeight: "1.5",color:"#000" }}>₹78</div>
              </div>

              </div>

              <h6 style={{color:"#000" }}>Type of Grinding</h6>
              <span style={{color:"#000" }}>Select one option</span>
              <div className="input-group"  style={{ display:"inline-grid"}}>
                <label className="input-option"  style={{ margin: "3px 0" }} >
                <input
                    type="radio"
                    value="Fine"
                    checked={selectedGrindingType === 'Fine'}
                    onChange={() => setSelectedGrindingType('Fine')}
                    style={{ borderColor: "gold", borderWidth: "2px", borderStyle: "solid" }}
                  />&nbsp;&nbsp;
                  <span style={{ color: "black" }}>Fine</span>
                </label>
                <label className="input-option" style={{ margin: "3px 0" }}>
                  <input
                    type="radio"
                    value="Normal"
                    checked={selectedGrindingType === 'Normal'}
                    onChange={() => setSelectedGrindingType('Normal')}
                    style={{ borderColor: "gold", borderWidth: "2px", borderStyle: "solid" }}
                  />&nbsp;&nbsp;
                  <span style={{ color: "black" }}>Normal</span>
                </label>
                <label className="input-option" style={{ margin: "3px 0" }}>
                  <input
                    type="radio"
                    value="Coarse"
                    checked={selectedGrindingType === 'Coarse'}
                    onChange={() => setSelectedGrindingType('Coarse')}
                    style={{ borderColor: "gold", borderWidth: "2px", borderStyle: "solid" }}
                  />&nbsp;&nbsp;
                  <span style={{ color: "black" }}>Coarse</span>
                </label>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h6>Description</h6>
                  <span style={{color:"#000" }}>{props.desc}</span>
                  <div className="d-flex justify-content-between">
                    <h2>Rs. {props.price}</h2>
                    <div className="text_down">
                      <img
                        src={Asset30}
                        className="cart_minus_img sub"
                        id="sub"
                        role="button"
                        alt="img"
                        onClick={dec}
                      />
                      <input
                        className="cart_quantity_input count"
                        type="text"
                        disabled
                        value={count}
                      />
                      <img
                        src={Asset14}
                        className="cart_plus_img add"
                        id="add"
                        role="button"
                        alt="img"
                        onClick={() => setCount(count + 1)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 d-grid gap-2">
                {!loading && (
                  <button
                    className="btn addcart"
                    type="button"
                    onClick={() => addToCart(props.id)}
                  >
                    Add to cart
                  </button>
                )}
                {loading && (
                  <button className="btn addcart" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Wait...
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
