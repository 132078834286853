import React, { useState,useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import MenuBar from "../MenuBar";
import Header from "../Header";
import Footer from "../../Footer/Footer";



function EditAddressModal(props) {
  const [loading, setLoading] = useState(false);
  // console.log(props);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [mobile, setMobile] = useState("");

  const [addressType, setAddressType] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");

  let user_id = localStorage.getItem("user_id");
  let address_id = localStorage.getItem("changeable_address_id");


// To get the Values
const params = useParams();


useEffect(() => {
  if (params.address_id) {
    getAddressValues(params.address_id);
  }
  window.scrollTo(0, 0);
  console.log(params,'params')
}, [params]);

//Get Address Details
const getAddressValues = (id) => {
  let formData = new FormData(); //formdata object

  // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
  const url = `https://chakkimart.in/admin/UserApi/getusersingleAddress?address_id=${id}`;

  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  axios
    .get(url, formData, config)
    .then((response) => {
      console.log(response);
      setName(response.data.data.address_name);

      setAddress(response.data.data.address);

      setAddressType(response.data.data.address_type);
      setCity(response.data.data.city);
      setState(response.data.data.state);

      setMobile(response.data.data.mobile);
      setPinCode(response.data.data.pincode);

      localStorage.setItem(
        "changeable_address_id",
        response.data.address.address_id
      );
    })
    .catch((error) => {
      console.log(error);
    });
};



  // Update Address

  const navigate = useNavigate();

  const addAddress = async (e) => {

    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("address_id", address_id);

    formData.append("address_type", addressType);
    formData.append("address", address);
  
    formData.append("address_name", name);
    formData.append("city", city);
    formData.append("pincode", pincode);
    formData.append("country", "India");
    formData.append("mobile", mobile);
    formData.append("state", state);

    const url = "https://chakkimart.in/admin/UserApi/updateUserAddress";
    const config = {
      headers: { "content-type": "multipart/form-data" },
      body: formData,
    };
    setLoading(true);
    axios.post(url, formData, config).then((response) => {
      console.log(response);
      setLoading(false);
   navigate(-1)
    });
    toast
      .success("Your Address Is Updated", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

 


  return (
    <div>
      <MenuBar/>
      <Header/>
      <div className="container">
      <form>
              <div className="row mt-5" style={{ marginLeft: "0px" }}>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      onChange={(e) => setMobile(e.target.value)}
                      value={mobile}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "0px" }}>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      City
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "0px" }}>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Address Type
                    </label>
                    <input
                      type="text"
                      class="form-control "
                      onChange={(e) => setAddressType(e.target.value)}
                      value={addressType}
                      placeholder="Home, Office etc."
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      State
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Pincode
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={pincode}
                      onChange={(e) => setPinCode(e.target.value)}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>
              {!loading && (
                <button
                  type="submit"
                  class="btn login_btn mb-5 mt-5"
                  style={{ backgroundColor: "white" }}
                  onClick={addAddress}
                >
                  Continue
                </button>
              )}
              {loading && (
                <button
                  className="btn login_btn mb-5 mt-5"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Adding Address...
                </button>
              )}
            </form>
      </div>
       
            <Footer/>
    </div>
  );
}

export default EditAddressModal;
