import React, { useState } from "react";
import Asset27 from "../../images/Asset 27.png";
import Asset14 from "../../images/Asset 14.png";
import Asset20 from "../../images/h1.png";
import Asset28 from "../../images/Asset 28.png";
import Asset13 from "../../images/Asset 13.png";
import Asset30 from "../../images/Asset 30.png";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import Modal from "../../CategoriesPage/Modal";
import DescriptionModal from "../../CategoriesPage/DescriptionModal";
import axios from "axios";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function Menu(props) {
  // console.log(props);
  let user_id = localStorage.getItem("user_id");
const navigate = useNavigate();

  const [tempData, setTempData] = useState("");
  const [modal, setModal] = useState(false);
  const [popularData, setPopularData] = useState("");
  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const options = {
    margin: 30,
    responsiveclass: true,
    nav: false,
    dots: false,
    autoplay: true,
    // navText: ["Prev", "Next"],
    // navText: ['<img className="" src= {Asset104}>', '<img className="" src= {Asset105}>'],
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 6,
      },
      1000: {
        items: 6,
      },
    },
  };

  const getModalData = (image, title, desc, price, id) => {
    let modalData = [image, title, desc, price, id];
    setTempData((item) => [1, ...modalData]);
    return setModal(true);
  };
  // console.log(tempData)

  const discriptionModalData = (title, desc, price, id) => {
    let modalData = [title, desc, price, id];

    setPopularData((item) => [1, ...modalData]);
    return setDiscriptionModal(true);
  };

  //Add to WhishList
  const addToWhishList = async (item) => {
    // setLoading(true);
    if(!user_id){
      navigate('/login');
    }
    else{
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("menu_id", item.menu_id); //append the values with key, value pair
  
      // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
      let url = "https://chakkimart.in/admin/UserApi/addWishlist";
      if (item.isWishList) {
        url = "https://chakkimart.in/admin/UserApi/deleteWishlist";
      }
      try {
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const apiResponse = await axios.post(url, formData, config);
        console.log(apiResponse, "result");
        {props.loadHomedata()};
  
        {
          !item.isWishList
            ? toast.success("Item is Added To Wish list", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            : toast.warning("Item is Removed from Wish list", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
        }
        // window.location.reload(false);
      } catch (error) {
        // setLoading(false);
      }
    }
  
  };

  //Remove From WhishList
  const removeToWhishList = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/deleteWishlist";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  //Update Cart Items

  const cartUpdate = async (id, quantity) => {
    if(!user_id){
      navigate('/login')
    }
   else if (quantity < 1) {
      removeFromCart(id);
    } else {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("menu_id", id);
      formData.append("quantity", quantity);

      const url = "https://chakkimart.in/admin/UserApi/updatecart";
      const config = {
        headers: { "content-type": "multipart/form-data" },
        body: formData,
      };
      // setLoading(true);
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          // setLoading(false);
          {props.loadHomedata()};

          // window.location.reload(false);

          // alert("Your Profile Update Successfully");
          // props.showAlert("Your Profile Updated Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    }
  };

  //Remove From WhishList
  const removeFromCart = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/removecart";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
      {props.loadHomedata()};

          // window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };
  

  return (
    <div>
      <div className="menu_container">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between">
              <h2 className="menu_heading">Menu</h2>
              {/* <a href="#" className="menu_link ">
                View All
              </a> */}
            </div>
            <OwlCarousel classNameName="owl-theme" {...options}>
              {props.setMenu.map((item, index) => {
                // console.log(props.setWhishlist[i].menu_id);
                // console.log(item.menu_id)
                // console.log(item)
                return (
                  <>
                    <motion.div
                      className="item"
                      whileHover={{
                        scale: 0.9,
                      }}
                    >
                      <div className="card menu_card">
                        <div className="card_image">
                          <img
                            src={`https://chakkimart.in/admin/${item.menu_image}`}
                            className="menu_img"
                            alt="img"
                            onClick={() =>
                              discriptionModalData(
                                item.menu_title,
                                item.menu_description,
                                item.menu_price,
                                item.menu_id
                              )
                            }
                          />
                        </div>

                        <div className="card-body">
                          <h6 className="menu_card_heading" style={{whiteSpace:'nowrap',fontSize:'16px'}}>
                          {item.main_category_title.length > 12
                              ? `${item.main_category_title.substring(0, 12)}...`
                              : item.main_category_title}
                            {/* {item.main_category_title.slice(0, 10)}... */}
                          </h6>
                          <p className="menu_disc">
                          {item.menu_description.length > 12
                              ? `${item.menu_description.substring(0, 12)}...`
                              : item.menu_description}
                            {/* {item.menu_description.slice(0, 15)}... */}
                          </p>
                          <div className="menu_price" style={{fontSize:'12px'}}>Rs. {item.menu_price}</div>
                          <div className="d-flex justify-content-between cart_quantity">
                            {/* <img
                                        src={Asset30}
                                        class="cart_minus_img sub"
                                        role="button"
                                        onClick={() =>
                                          cartUpdate(
                                            item.menu_id,
                                            Number(item.quantity) - 1
                                          )
                                        }
                                        alt="img"
                                      />
                                      <input
                                        class=" cart_quantity_input count"
                                        id="quantity"
                                        type="text"
                                        value={item.quantity}
                                      />
                                      <img
                                        src={Asset14}
                                        class="cart_plus_img add"
                                        role="button"
                                        alt="img"
                                        onClick={() =>
                                          cartUpdate(
                                            item.menu_id,
                                            Number(item.quantity) + 1
                                          )
                                        }
                                      /> */}

                            {/* <img
                              src={Asset14}
                              className="menu_add_product_img"
                              alt="img"
                              onClick={() =>
                                getModalData(
                                  item.menu_title,
                                  item.menu_description,
                                  item.menu_price,
                                  item.menu_image,
                                  item.menu_id
                                )
                              }
                            /> */}
                            {item?.isCartList ? (
                              <>
                                <div class="d-flex justify-content-center cart_quantity">
                                  <img
                                    src={Asset30}
                                    class="cart_minus_img sub"
                                    role="button"
                                    onClick={() =>
                                      cartUpdate(
                                        item.menu_id,
                                        Number(item.quantity) - 1
                                      )
                                    }
                                    alt="img"
                                  />
                                  <input
                                    class=" cart_quantity_input count"
                                    id="quantity"
                                    type="text"
                                    value={item?.isCartList && item.quantity}
                                  />
                                  <img
                                    src={Asset14}
                                    class="cart_plus_img add"
                                    role="button"
                                    alt="img"
                                    onClick={() =>
                                      cartUpdate(
                                        item.menu_id,
                                        Number(item.quantity) + 1
                                      )
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <img
                                  src={Asset14}
                                  class="popular_add_product_img"
                                  alt="img"
                                  onClick={() =>
                                    getModalData(
                                      item.menu_title,
                                      item.menu_description,
                                      item.menu_price,
                                      item.menu_image,
                                      item.menu_id
                                    )
                                  }
                                />
                              </>
                            )}
                            {/* <img
                              src={item?.isWishList ? Asset20 : Asset13}
                              class="popular_like_product_img"
                              alt="img"
                              onClick={() => addToWhishList(item)}
                            /> */}
                                 <i
                                class={
                                  item.isWishList
                                    ? "fa-solid fa-heart likes"
                                    : "fa-regular fa-heart"
                                }
                                style={{fontSize:'24px',color:'gold'}}
                                onClick={() => addToWhishList(item)}
                              ></i>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </>
                );
              })}
            </OwlCarousel>
            {modal === true ? (
              <Modal
                hide={() => setModal(false)}
                image={tempData[4]}
                price={tempData[3]}
                title={tempData[1]}
                desc={tempData[2]}
                id={tempData[5]}
              />
            ) : (
              ""
            )}
            {discriptionModal === true ? (
              <DescriptionModal
                hide={() => setDiscriptionModal(false)}
                price={popularData[3]}
                title={popularData[2]}
                desc={popularData[1]}
                id={popularData[4]}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
