import React, { useState, useEffect } from "react";
import axios from "axios";
import Asset301 from "../images/Asset 301.png";
import Asset38 from "../images/Asset 38.png";
import Asset40 from "../images/Asset 40.png";

import { toast } from "react-toastify";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import Footer from "../Footer/Footer";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


function Profile() {
  let user_id = localStorage.getItem("user_id");
  let mobile = localStorage.getItem("mobile_number");

  const [profilePicture, setProfilePicture] = useState("");
  const [profileFirstName, setProfileFirstName] = useState("");
  const [profileLastName, setProfileLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [getImage, setGetImage] = useState("");

  const [profileBox, setProfileBox] = useState(false);
  const [loading, setLoading] = useState(false);

 

  const getProfileInfo = (event) => {
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/user_detail_by_id/?user_id=${UserId}`;
    const url = `https://chakkimart.in/admin/UserApi/getProfile/?mobile=${mobile}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response.data.profile)
        setProfileFirstName(response.data.profile.user_firstname);
        setProfileLastName(response.data.profile.user_lastname);
        setEmail(response.data.profile.user_email);

        setMobileNumber(response.data.profile.user_mobile);
        setGetImage(response.data.profile.user_profile);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getProfileInfo();
  }, []);

  // console.log(getImage)
  const uploadPicture = (e) => {
    // setSendImage(e.target.files[0]);
    setProfilePicture(e.target.files[0]);
  };

  //POST UPDATED PROFILE
  const profileUpdate = async (e) => {
    e.preventDefault();

    if( !profileFirstName ||
      !profileLastName ||
      !mobileNumber ||!email){
        toast.warn("All Feilds are Required!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
    }
    else {
      let formData = new FormData();

      formData.append("user_id", user_id);
      formData.append("first_name", profileFirstName);
      formData.append("last_name", profileLastName);
      formData.append("mobile", mobileNumber);
  
      formData.append("email", email);
      !profilePicture
        ? formData.append("profile", getImage)
        : formData.append("profile", profilePicture);
  
      const url = "https://chakkimart.in/admin/UserApi/updateprofile";
      const config = {
        headers: { "content-type": "multipart/form-data" },
        body: formData,
      };
      setLoading(true);
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          setLoading(false);
  
          toast.success("Your Profile Updated Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          window.location.reload(false);
  
          // alert("Your Profile Update Successfully");
          // props.showAlert("Your Profile Updated Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    
 
  };

  const removeProfilePhoto = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("mobile", mobile);

    const url = "https://chakkimart.in/admin/UserApi/deleteprofilephoto";
    const config = {
      headers: { "content-type": "multipart/form-data" },
      body: formData,
    };
    setLoading(true);
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setProfileBox(response.data.message);
        toast.success("Your Profile Photo Is Removed", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div>
      <MenuBar />
      <Header />
      <form onSubmit={profileUpdate}>
        <div
          class="profile_container"
          style={{ padding: "100px" }}
       
        >
          <div class="container inner_part"    initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          transition={{
            delay: 0.2,
            duration: 0.5,
        
          }}>
            <div class="row">
              <div class="col-md-3 text-center">
                <div class=" profile_col_1" style={{ marginTop: "-115px" }}>
                  <div class="profile-section ">
                    {/* <img src={`https://hotelapsara.co/${getImage}`} alt="img"/> */}

                    {getImage ? (
                      <>
                        <div className="col-md-2 text-center">
                          {!profilePicture ? (
                            <img
                              src={`https://chakkimart.in/admin/${getImage}`}
                              alt="getImage"
                              // onClick={() => setModal1(true)}
                              className="mb-2"
                              style={{
                                width: "100px",
                                height: "100px",
                                float: "left",
                              }}
                            ></img>
                          ) : (
                            <img
                              src={
                                profilePicture
                                  ? URL.createObjectURL(profilePicture)
                                  : getImage
                              }
                              alt="sendImage"
                              className="mb-2"
                              style={{
                                width: "100px",
                                height: "100px",
                                float: "left",
                              }}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-2 text-center">
                          {!profilePicture ? (
                            <img
                              src={Asset301}
                              alt="getImage"
                              className="mb-2"
                              style={{
                                width: "100px",
                                height: "100px",
                                float: "left",
                                border: "1px solid black",
                              }}
                            ></img>
                          ) : (
                            <img
                              src={
                                profilePicture
                                  ? URL.createObjectURL(profilePicture)
                                  : getImage
                              }
                              alt="sendImage"
                              className="mb-2"
                              style={{
                                width: "100px",
                                height: "100px",
                                float: "left",
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                    {/* {getImage ? (
                      <>
                        <div className="col-md-2 text-center">
                          <div class="upload-btn-wrapper">
                        
                            {!profilePicture ? (
                              <img 
                              src={`https://hotelapsara.co/${getImage}`}
                                alt="getImage"
                                // onClick={() => setModal1(true)}
                                className="mb-2"
                              ></img>
                            ) : (
                              <img
                                src={
                                  profilePicture
                                    ? URL.createObjectURL(profilePicture)
                                    : getImage
                                }
                                alt="sendImage"
                                className="mb-2"
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-2 text-center">
                          <div class="upload-btn-wrapper">
                            {!getImage ? (
                              <img
                                src={Asset301}
                                alt="getImage"
                                className="mb-2"
                              ></img>
                            ) : (
                              <img
                                src={
                                  getImage
                                    ? URL.createObjectURL(getImage)
                                    : profilePicture
                                }
                                alt="sendImage"
                                className="mb-2"
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )} */}
                  </div>
                  <div
                    className="col-md-2 plus_btn"
                    style={{ marginTop: "-40px", marginLeft: "60px" }}
                  >
                    <div class="upload-btn-wrapper1  ">
                      <img
                        class=""
                        src={Asset38}
                        style={{ height: "25px", width: "25px" }}
                        alt="img"
                      ></img>

                      <input
                        type="file"
                        name="myfile"
                        accept="image/jpg, image/jpeg"
                        onChange={uploadPicture}
                      />
                    </div>
                  </div>
                  {getImage !== null && getImage !== ""  && (
                    <>
                      <div
                        className="col-md-2 plus_btn"
                        style={{ marginTop: "-35px", marginLeft: "154px" }}
                      >
                        <div class="upload-btn-wrapper  ">
                          <img
                            class="profile_remove_img"
                            src={Asset40}
                            alt="img"
                            onClick={removeProfilePhoto}
                          ></img>
                        </div>
                      </div>

                      <>
                        <div
                          className="col-md-2 plus_btn"
                          style={{ marginTop: "-35px", marginLeft: "154px" }}
                        ></div>
                      </>
                    </>
                  )}
                </div>
              </div>
              <div className="row" style={{ marginLeft: "0px" }}>
                <div className="col-md-6 mt-3">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      value={profileFirstName}
                      onChange={(e) => {
                        setProfileFirstName(e.target.value);
                      }}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      value={profileLastName}
                      onChange={(e) => {
                        setProfileLastName(e.target.value);
                      }}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "0px" }}>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control profile_input"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label
                      class="form-label profile_label"
                      style={{
                        color: "#7D453D",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile no.
                    </label>
                    <input
                      type="text"
                      class="form-control profile_input"
                      id="zip"
                      value={mobileNumber}
                      maxLength="10"
                      minLength="10"
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #DDD6CE",
                        borderRadius: "10px",
                        padding: "7px",
                      }}
                      disabled
                    />
                  </div>
                </div>
                <div className="text-center">
                  {!loading && (
                    <button type="submit" className="btn login_btn">
                      Profile Update
                    </button>
                  )}
                  {loading && (
                    <button className="btn login_btn" type="button" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Profile Updating...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
}

export default Profile;
