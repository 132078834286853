import React, { useState, useEffect, useCallback } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import Asset22 from "../images/Asset 22.jpg";
import Asset26 from "../images/Asset 26.jpg";
import categorybanner from "../images/categorybanner.png";
import Asset25 from "../images/Asset 25.jpg";
import Asset24 from "../images/Asset 24.jpg";
import Asset14 from "../images/Asset 14.png";
import Asset20 from "../images/h1.png";
import Asset13 from "../images/Asset 13.png";
import Asset30 from "../images/Asset 30.png";

import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../Home/Loader/Loader";
import Modal from "./Modal";
import PopularModal from "./PopularModal";
import DescriptionModal from "./DescriptionModal";
// import { motion } from "framer-motion";

function CategoriesPage() {
  const [cardData, setCardData] = useState([]);
  const [addtoCardResponse, setAddtoCardResponse] = useState([]);
  const [whishListResponse, setWhishListResponse] = useState([]);

  const [popularCardData, setPopularCardData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [allItems, setAllItems] = useState(true);
  const [popularItems, setPopularItems] = useState(false);

  const [modal, setModal] = useState(false);
  const [tempData, setTempData] = useState("");
  const [whishListMessage, setWhishListMessage] = useState("");

  const [discriptionModal, setDiscriptionModal] = useState(false);
  const [popularData, setPopularData] = useState("");

  const [likeImage, setLikeImage] = useState(true);
  const [heartImage, setHeartImage] = useState("");
  const [IDs, setIDs] = useState("");

  let service_id = localStorage.getItem("service_id");
  let user_id = localStorage.getItem("user_id");

  const params = useParams();
  const navigate = useNavigate();

  const getMenus = useCallback(async () => {
    // setLoading(true);
    try {
      const apiResponse = await Promise.all([
        axios.get(
          `https://chakkimart.in/admin/UserApi/getMenu?main_category_id=${params.category_id}`
        ),
        axios.get(
          `https://chakkimart.in/admin/UserApi/getcartlist?user_id=${user_id}`
        ),
        axios.get(
          `https://chakkimart.in/admin/UserApi/getWishlist?user_id=${user_id}`
        ),
      ]);
      const menusList = apiResponse[0].data.menu;
      const whishLists = apiResponse[2].data.wishlist;
      const cartList = apiResponse[1].data.items;
      // setCardData(menusList);
      console.log(whishLists, menusList, cartList, "apiResponse");
      // console.log(params.category_id)
      localStorage.setItem("service_id", params.category_id);

      if (apiResponse[0]) {
        setCardData(menusList);
      }
      menusList.forEach((menuItem, index) => {
        whishLists.forEach((wishItem) => {
          if (wishItem && menuItem.menu_id === wishItem.menu_id) {
            menusList[index].isWishList = true;
          }
        });
      });
      setCardData(menusList);
      // setLoading(false);

      menusList.forEach((menuItem, index) => {
        cartList.forEach((cartItem) => {
          if (cartItem && menuItem.menu_id === cartItem.menu_id) {
            menusList[index].isCartList = true;
            menusList[index].quantity = cartItem.quantity;
            // localStorage.setItem("service_id", params.category_id);
          }
        });
      });
      localStorage.setItem("cart", cartList.length);
    } catch (err) {
      console.log(err);
      // setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (params.category_id) {
      getMenus();

      // setAddtoCardResponse(res[1].data.items);
      // setWhishListResponse(res[2].data.wishlist);

      // setAllItems(true);
      // setPopularItems(false);
      // localStorage.setItem("service_id", params.category_id);

      // res[0].data.menu.forEach((element, index) => {
      //   // console.log(element)
      //   res[2].data.wishlist.forEach((obj) => {
      //     // console.log(obj)
      //     if (element.menu_id === obj.menu_id) {
      //       // console.log(element.menu_id, obj.menu_id);
      //       // console.log(cardData[index]);
      //       cardData[index].heartImage = true;

      //       return setHeartImage(element.menu_id === obj.menu_id);
      //     }
      //   });
      // });
    }
  }, [params, getMenus]);
  //Update Cart Items

  const cartUpdate = async (id, quantity) => {
    if (quantity < 1) {
      removeFromCart(id);
    } else {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("menu_id", id);
      formData.append("quantity", quantity);

      const url = "https://chakkimart.in/admin/UserApi/updatecart";
      const config = {
        headers: { "content-type": "multipart/form-data" },
        body: formData,
      };
      // setLoading(true);
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          // setLoading(false);
          // window.location.reload(false);

          getMenus();
          // alert("Your Profile Update Successfully");
          // props.showAlert("Your Profile Updated Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    }
  };

  //Remove From WhishList
  const removeFromCart = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/removecart";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          getMenus();
          // window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  //For Popular Items
  const getpopularItems = useCallback(async (id) => {
    // setLoading(true);
    try {
      const apiResponse = await Promise.all([
        axios.get(
          `https://chakkimart.in/admin/UserApi/getCategoryPopularProducts?main_category_id=${id}`
        ),
        axios.get(
          `https://chakkimart.in/admin/UserApi/getcartlist?user_id=${user_id}`
        ),
        axios.get(
          `https://chakkimart.in/admin/UserApi/getWishlist?user_id=${user_id}`
        ),
      ]);
      const populatItemList = apiResponse[0].data.items;
      const whishLists = apiResponse[2].data.wishlist;
      const cartList = apiResponse[1].data.items;
      console.log(populatItemList, whishLists, cartList, "apiResponse");

      populatItemList.forEach((menuItem, index) => {
        whishLists.forEach((wishItem) => {
          if (wishItem && menuItem.menu_id === wishItem.menu_id) {
            populatItemList[index].isWishList = true;
          }
        });
      });
      setPopularCardData(populatItemList);
      // setLoading(false);

      populatItemList.forEach((menuItem, index) => {
        cartList.forEach((cartItem) => {
          if (cartItem && menuItem.menu_id === cartItem.menu_id) {
            populatItemList[index].isCartList = true;
            populatItemList[index].quantity = cartItem.quantity;
            localStorage.setItem("service_id", id);
            setPopularItems(true);
            setAllItems(false);
          }
        });
      });
    } catch (err) {
      console.log(err);
      // setLoading(false);
    }
  }, []);

  const getPopularItems = (id) => {
    // setLoading(true);
    Promise.all([
      axios.get(
        `https://chakkimart.in/admin/UserApi/getCategoryPopularProducts?main_category_id=${id}`
      ),
      axios.get(
        `https://chakkimart.in/admin/UserApi/getcartlist?user_id=${user_id}`
      ),
      axios.get(
        `https://chakkimart.in/admin/UserApi/getWishlist?user_id=${user_id}`
      ),
    ])

      .then((response) => {
        console.log(response);
        // setLoading(false);
        setPopularItems(true);
        setAllItems(false);
        // setServiceName(response.data.result.service_link);
        setPopularCardData(response[0].data.items);
        localStorage.setItem("service_id", id);
        // localStorage.setItem("service_name",response.data.result.service_title);

        // localStorage.setItem('service_name',response.data.result.service_link)
      })

      .catch((error) => {
        console.log(error);
        // setLoading(false);
      });
  };

  // const getDataByID = (id) => {
  //   let formData = new FormData(); //formdata object

  //   // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
  //   const url = `https://chakkimart.in/admin/UserApi/getMenu?main_category_id=${id}`;

  //   const config = {
  //     headers: { "content-type": "multipart/form-data" },
  //   };
  //   setLoading(true);
  //   axios
  //     .get(url, formData, config)
  //     .then((response) => {
  //       console.log(response);
  //       setLoading(false);
  //       // localStorage.setItem("menu_id",response.data.menu.menu_id)
  //       // setServiceName(response.data.result.service_link);
  //       setAllItems(true);
  //       setPopularItems(false);
  //       setCardData(response.data.menu);
  //       localStorage.setItem("service_id", id);

  //     })

  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };

  // const getCartData = () => {
  //   let formData = new FormData(); //formdata object

  //   // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
  //   const url = `https://chakkimart.in/admin/UserApi/getcartlist?user_id=${user_id}`;

  //   const config = {
  //     headers: { "content-type": "multipart/form-data" },
  //   };
  //   setLoading(true);
  //   axios
  //     .get(url, formData, config)
  //     .then((response) => {
  //       console.log(response);
  //       setLoading(false);
  //       // localStorage.setItem("menu_id",response.data.menu.menu_id)
  //       // setServiceName(response.data.result.service_link);
  //       // setAllItems(true);
  //       // setPopularItems(false);
  //       // setCardData(response.data.menu);
  //       // localStorage.setItem("service_id", id);
  //       // localStorage.setItem("service_name",response.data.result.service_title);

  //       // localStorage.setItem('service_name',response.data.result.service_link)
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };

  // const getPopularItems = (id) => {
  //   let formData = new FormData(); //formdata object

  //   // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
  //   const url = `https://chakkimart.in/admin/UserApi/getCategoryPopularProducts?main_category_id=${id}`;

  //   const config = {
  //     headers: { "content-type": "multipart/form-data" },
  //   };
  //   setLoading(true);
  //   axios
  //     .get(url, formData, config)
  //     .then((response) => {
  //       console.log(response);
  //       setLoading(false);

  //       setPopularItems(true);
  //       setAllItems(false);
  //       // setServiceName(response.data.result.service_link);
  //       setPopularCardData(response.data.items);
  //       localStorage.setItem("service_id", id);
  //       // localStorage.setItem("service_name",response.data.result.service_title);

  //       // localStorage.setItem('service_name',response.data.result.service_link)
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false);
  //     });
  // };

  const getModalData = (image, title, desc, price, id) => {
    let modalData = [image, title, desc, price, id];

    setTempData((item) => [1, ...modalData]);
    return setModal(true);
  };

  const discriptionModalData = (title, desc, price, id) => {
    let modalData = [title, desc, price, id];

    setPopularData((item) => [1, ...modalData]);
    return setDiscriptionModal(true);
  };
  // console.log(discriptionModal)
  //Add To WhishList
  const addToWhishList = async (item) => {
    // setLoading(true);
    if(!user_id){
      navigate('/login')
    }
    else{

    
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", item.menu_id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    let url = "https://chakkimart.in/admin/UserApi/addWishlist";
    if (item.isWishList) {
      url = "https://chakkimart.in/admin/UserApi/deleteWishlist";
    }
    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const apiResponse = await axios.post(url, formData, config);
      console.log(apiResponse, "result");
      await getMenus();
      // window.location.reload(false);
    } catch (error) {
      // setLoading(false);
    }
  }
  };

  //Remove From WhishList
  const removeToWhishList = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/deleteWishlist";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          setLikeImage(true);
        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  // console.log(cardData)
  return (
    <div>
      {/* {loading ? (
        <Loader />
      ) : (
        <> */}
          <MenuBar />
          <Header />

          <div class="container carousel_container mb-3">
            <div class="row category_row mb-3">
              <div class="d-flex">
                <a
                  role="button"
                  // onClick={() => window.location.reload(false)}
                >
                  All
                </a>

                <Link
                  role="button"
                  // onClick={() => getpopularItems(service_id)}
                  to={`/home/category/${service_id}/all_items/popular_items`}
                  style={{ marginLeft: "30px" }}
                >
                  Popular
                </Link>
              </div>
              {allItems && <div class="hb"></div>}
              {popularItems && <div class="hb33"></div>}
            </div>

            {/* Carausel Part */}
          </div>
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src={categorybanner}
                  class="d-block w-100 carousel_img"
                  alt="..."
                />
              </div>
              {/* <div class="carousel-item">
                <img
                  src={Asset25}
                  class="d-block w-100 carousel_img"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src={Asset24}
                  class="d-block w-100 carousel_img"
                  alt="..."
                />
              </div>
              <div class="carousel-item">
                <img
                  src={Asset22}
                  class="d-block w-100 carousel_img"
                  alt="..."
                />
              </div> */}
            </div>
            {/* <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button> */}
          </div>
        {/* </>
      )} */}

      {/* Card Items */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {allItems && (
            <>
              <div className="popular_items_container">
                <div className="container">
                  <div className="row justify-content-center">
                  {/* <div className="col-md-1"></div> */}
                    {cardData &&
                      cardData.map((item, index) => {
                        // console.log('card Data',item)
                        return (
                          <>
                          
                            <div className="col-md-2 col-6">
                              <div
                                class="item"
                                whileHover={{
                                  scale: 0.9,
                                }}
                              >
                                <div class="card popular_card33 mx-auto">
                                  <img
                                    src={`https://chakkimart.in/admin/${item.menu_image}`}
                                    class="popular_img33"
                                    alt="img"
                                    onClick={() =>
                                      discriptionModalData(
                                        item.menu_title,
                                        item.menu_description,
                                        item.menu_price,
                                        item.menu_id
                                      )
                                    }
                                  />
                                  <div class="card-body popular_card_body">
                                    <div class="d-flex justify-content-between mt-1">
                                      <h6 class="menu_card_heading">
                                        {item.menu_title.slice(0, 15)}
                                      </h6>
                                      {/* <img
                                        src={
                                          item?.isWishList ? Asset20 : Asset13
                                        }
                                        class="popular_like_product_img"
                                        alt="img"
                                        onClick={() => addToWhishList(item)}
                                      /> */}
                                      <i
                                        class={
                                          item.isWishList
                                            ? "fa-solid fa-heart likes"
                                            : "fa-regular fa-heart"
                                        }
                                        style={{ fontSize: "20px",cursor:'pointer', color: 'gold'
                                         }}
                                        onClick={() => addToWhishList(item)}
                                      ></i>
                                      {/* {cardData[index].menu_id ===
                                      item.menu_id ? (
                                        <>
                                          <img
                                            src={Asset20}
                                            class="popular_like_product_img"
                                            alt="img"
                                            onClick={() =>
                                              removeToWhishList(item.menu_id)
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={Asset13}
                                            class="popular_like_product_img"
                                            alt="img"
                                            onClick={() =>
                                              addToWhishList(item.menu_id)
                                            }
                                          />
                                        </>
                                      )} */}
                                    </div>
                                    <p class="menu_disc">
                                      {item.menu_description.slice(0, 25)}...
                                    </p>
                                    <div class="d-flex justify-content-between">
                                      <p class="menu_price">
                                        Rs. {item.menu_price}
                                      </p>
                                      {/* {item.menu_id} */}
                                      {item?.isCartList ? (
                                        <>
                                          <div class="d-flex justify-content-center cart_quantity">
                                            <img
                                              src={Asset30}
                                              class="cart_minus_img sub"
                                              role="button"
                                              onClick={() =>
                                                cartUpdate(
                                                  item.menu_id,
                                                  Number(item.quantity) - 1
                                                )
                                              }
                                              alt="img"
                                            />
                                            <input
                                              class=" cart_quantity_input count"
                                              id="quantity"
                                              type="text"
                                              value={
                                                item?.isCartList &&
                                                item.quantity
                                              }
                                            />
                                            <img
                                              src={Asset14}
                                              class="cart_plus_img add"
                                              role="button"
                                              alt="img"
                                              onClick={() =>
                                                cartUpdate(
                                                  item.menu_id,
                                                  Number(item.quantity) + 1
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={Asset14}
                                            class="popular_add_product_imgss"
                                            alt="img"
                                            onClick={() =>
                                              getModalData(
                                                item.menu_title,
                                                item.menu_description,
                                                item.menu_price,
                                                item.menu_image,
                                                item.menu_id
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {modal === true ? (
        <Modal
          hide={() => setModal(false)}
          image={tempData[4]}
          price={tempData[3]}
          title={tempData[1]}
          desc={tempData[2]}
          id={tempData[5]}
        />
      ) : (
        ""
      )}
      {discriptionModal === true ? (
        <DescriptionModal
          hide={() => setDiscriptionModal(false)}
          price={popularData[3]}
          title={popularData[2]}
          desc={popularData[1]}
          id={popularData[4]}
        />
      ) : (
        ""
      )}
      {/* Popular Card data  */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {popularItems && (
            <>
              <div className="popular_items_container">
                <div className="container">
                  <div className="row justify-content-center">
                  {/* <div className="col"></div> */}
                    {popularCardData &&
                      popularCardData.map((item, i) => {
                        return (
                          <>
                            <div className="col-md-2">
                              <div class="item">
                                <div class="card popular_card33 mx-auto">
                                  <img
                                    src={`https://hotelapsara.co/${item.menu_image}`}
                                    class="popular_img33"
                                    alt="img"
                                    onClick={() =>
                                      discriptionModalData(
                                        item.menu_title,
                                        item.menu_description,
                                        item.menu_price,
                                        item.menu_id
                                      )
                                    }
                                  />
                                  <div class="card-body popular_card_body">
                                    <div class="d-flex justify-content-between">
                                      <h6 class="menu_card_heading">
                                        {item.menu_title}
                                      </h6>
                                      {/* <img
                                        src={
                                          item?.isWishList ? Asset20 : Asset13
                                        }
                                        class="popular_like_product_img"
                                        alt="img"
                                        onClick={() => addToWhishList(item)}
                                      /> */}
                                      <i
                                        class={
                                          item.isWishList
                                            ? "fa-solid fa-heart likes"
                                            : "fa-regular fa-heart"
                                        }
                                        style={{ fontSize: "24px",cursor:"pointer",color:"gold" }}
                                        onClick={() => addToWhishList(item)}
                                      ></i>
                                    </div>
                                    <p class="menu_disc">
                                      {item.menu_description.slice(0, 25)}...
                                    </p>
                                    <div class="d-flex justify-content-between">
                                      <p class="menu_price">
                                        Rs. {item.menu_price}
                                      </p>
                                      {item?.isCartList ? (
                                        <>
                                          <div class="d-flex justify-content-center cart_quantity">
                                            <img
                                              src={Asset30}
                                              class="cart_minus_img sub"
                                              role="button"
                                              onClick={() =>
                                                cartUpdate(
                                                  item.menu_id,
                                                  Number(item.quantity) - 1
                                                )
                                              }
                                              alt="img"
                                            />
                                            <input
                                              class=" cart_quantity_input count"
                                              id="quantity"
                                              type="text"
                                              value={
                                                item?.isCartList &&
                                                item.quantity
                                              }
                                            />
                                            <img
                                              src={Asset14}
                                              class="cart_plus_img add"
                                              role="button"
                                              alt="img"
                                              onClick={() =>
                                                cartUpdate(
                                                  item.menu_id,
                                                  Number(item.quantity) + 1
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            src={Asset14}
                                            class="popular_add_product_img"
                                            alt="img"
                                            onClick={() =>
                                              getModalData(
                                                item.menu_title,
                                                item.menu_description,
                                                item.menu_price,
                                                item.menu_image,
                                                item.menu_id
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                      {/* <img
                                        src={Asset14}
                                        class="popular_add_product_img"
                                        alt="img"
                                        onClick={() =>
                                          getModalData(
                                            item.menu_title,
                                            item.menu_description,
                                            item.menu_price,
                                            item.menu_image,
                                            item.menu_id
                                          )
                                        }
                                      /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {modal === true ? (
        <Modal
          hide={() => setModal(false)}
          image={tempData[4]}
          price={tempData[3]}
          title={tempData[1]}
          desc={tempData[2]}
          id={tempData[5]}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default CategoriesPage;
