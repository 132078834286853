import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import axios from "axios";
import Loader from "../Home/Loader/Loader";
import Asset30 from "../images/Asset 30.png";
import Asset14 from "../images/Asset 14.png";
import Asset84 from "../images/Asset 84.png";
import Asset96 from "../images/Asset 96.png";

import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PaymentModal from "./PaymentModal";
import MenuBar from "../Home/MenuBar";
import { motion } from "framer-motion";

function MyCart() {
  const [cartData, setCartData] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [coupenValue, setCoupenValue] = useState("");
  const [discount, setDiscount] = useState("");
  const [preDiscount, setPreDiscount] = useState("");

  const [totalAmountPay, setTotalAmountPay] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(false);
  const [nullMessage, setNullMessage] = useState(false);
  const [cartPage, setCartPage] = useState(false);

  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  let user_id = localStorage.getItem("user_id");
  let total_amount = localStorage.getItem("total_amount_pay");
  const navigate = useNavigate();

  let localStorageData = localStorage.getItem("address_values"),
    retrivedData = JSON.parse(localStorageData);

  const loadCartdata = (event) => {
    let formData = new FormData(); //formdata object

    // const url = "https://192.168.1.16/vipdoor/UserApi/home_screen_data";
    const url = `https://chakkimart.in/admin/UserApi/getcartlist?user_id=${user_id}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    setLoading(true);
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        if (response.data.message === "Cart is empty") {
          setNullMessage(true);
          setLoading(false);
          setCartPage(false);
     
        } else {
          setLoading(false);
          setCartPage(true);
          setCartData(response.data.items);
          setTotalPrice(response.data.items_price);
          localStorage.setItem("item_price", response.data.items_price);
          localStorage.setItem(
            "total_amount_pay",
            response.data.total_amount_pay
          );

          setPreDiscount(response.data.discount);
          localStorage.setItem("preDiscount", response.data.discount);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if(!user_id){
      navigate('/login');
    }
    else{
      loadCartdata();
    }

   
    window.scrollTo(0, 0);
  }, []);

  //Update Cart Items

  const cartUpdate = async (id, quantity) => {
    if (quantity < 1) {
      return;
    } else {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("menu_id", id);
      formData.append("quantity", quantity);

      const url = "https://chakkimart.in/admin/UserApi/updatecart";
      const config = {
        headers: { "content-type": "multipart/form-data" },
        body: formData,
      };
      setLoading(true);
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          setLoading(false);
          loadCartdata();
          // window.location.reload(false);

          // alert("Your Profile Update Successfully");
          // props.showAlert("Your Profile Updated Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  //Remove From WhishList
  const removeFromCart = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("menu_id", id); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/removecart";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          // window.location.reload(false);
          loadCartdata();
        localStorage.setItem("cart", response.data.cart.length);

        })
        .catch((error) => {
          console.log(error);
        });
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  //Apply Copen
  const applyCopen = async (id) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("coupon_code", coupenValue); //append the values with key, value pair

    // const url = "https://192.168.1.16/vipdoor/UserApi/pan_personal_details";
    const url = "https://chakkimart.in/admin/UserApi/applycoupon";

    try {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      setLoading(true);

      const data = await axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          setLoading(false);
          //   window.location.reload(false);
          if (
            response.data.status === false &&
            response.data.message === "Coupon Not Exists"
          ) {
            toast.error("Coupon Not Exists", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (
            response.data.status === false &&
            response.data.message === "Coupon is expired"
          ) {
            toast.error("Coupon is expired", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (
            response.data.status === false &&
            response.data.message === "You have already used this coupon"
          ) {
            toast.error("You have already used this coupon", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.success("Coupon is Applied Successfully", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setDiscount(response.data.coupon_discount);
            localStorage.setItem(
              "coupon_discount",
              response.data.coupon_discount
            );
            localStorage.setItem("coupon_code", response.data.coupon_code);
            localStorage.setItem(
              "total_amount_pay",
              response.data.total_amount_pay
            );

            setTotalAmountPay(response.data.total_amount_pay);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //Add Delivery Charge
  function dilivaryCharge() {
    if (deliveryCharge === false) {
      setDeliveryCharge(true);
      localStorage.setItem("deliveryCharge", 99);
      localStorage.setItem("delivery_type", "Delivery");
      localStorage.setItem(
        "total_amount_pay",
        Number(total_amount)+99
      );


      // console.log(deliveryCharge)
    } else {
      setDeliveryCharge(false);
      localStorage.setItem("deliveryCharge", 0);
      localStorage.setItem("delivery_type", "Take Out");
      localStorage.setItem(
        "total_amount_pay",
        Number(total_amount)-99
      );
    }
  }

  //   console.log(discount)

  //Select Address First
  const selectAddress = () => {
    toast.error("Please Select Address First", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };



  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MenuBar />
          <Header />

          {nullMessage && (
            <>
              <div>
                <div
                  className="text-center p-3"
                  style={{ backgroundColor: "#F7F4F0" }}
                >
                  <img
                    src={Asset96}
                    alt="img"
                    style={{ width: "200px", height: "200p" }}
                  ></img>
                </div>
              </div>
            </>
          )}

          {cartPage && (
            <>
              <div class="cart_container">
                <div class="container">
                  <div class="row">
                    <table class="table cart_table" id="myTable">
                      <thead>
                        <tr>
                          <th class="text-center" style={{ width: "55%" }}>
                            Product
                          </th>
                          <th class="text-center" style={{ width: "15%" }}>
                            Unit Price
                          </th>
                          <th class="text-center" style={{ width: "15%" }}>
                            Quantity
                          </th>
                          <th class="text-center" style={{ width: "15%" }}>
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartData &&
                          cartData.map((item) => {
                            // console.log(item);
                            return (
                              <>
                                <motion.tr
                                  initial={{ x: "100vw" }}
                                  animate={{ x: 0 }}
                                  transition={{
                                    delay: 0.2,
                                    duration: 0.5,
                                    stiffness: 100,
                                    type: "spring",
                                  }}
                                >
                                  <td class="text-center">
                                    <div class="d-flex">
                                      <div class="cart_img_sect">
                                        <img
                                          src={`https://chakkimart.in/admin/${item.image}`}
                                          class="cart_img"
                                          alt="img"
                                        />
                                      </div>
                                      <div>
                                        <div class="cart_product_detail">
                                          {item.name}
                                        </div>
                                        <div class="cart_product_disc">
                                          {item.description}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <input
                                      type="text"
                                      class="form-control text-center cart_input price"
                                      id="unit_price"
                                      value={item.price}
                                      readonly
                                    />
                                  </td>
                                  <td class="text-center">
                                    <div class="d-flex justify-content-center cart_quantity">
                                      <img
                                        src={Asset30}
                                        class="cart_minus_img sub"
                                        role="button"
                                        onClick={() =>
                                          cartUpdate(
                                            item.menu_id,
                                            Number(item.quantity) - 1
                                          )
                                        }
                                        alt="img"
                                      />
                                      <input
                                        class=" cart_quantity_input count"
                                        id="quantity"
                                        type="text"
                                        value={item.quantity}
                                      />
                                      <img
                                        src={Asset14}
                                        class="cart_plus_img add"
                                        role="button"
                                        alt="img"
                                        onClick={() =>
                                          cartUpdate(
                                            item.menu_id,
                                            Number(item.quantity) + 1
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td
                                    class="text-center"
                                    style={{ fontWeight: "500" }}
                                  >
                                    <input
                                      type="text"
                                      class="form-control text-center cart_input total"
                                      id="total"
                                      value={item.total_price}
                                      readonly
                                      style={{ fontWeight: "500" }}
                                    />
                                    <button
                                      type="button"
                                      class="btn cart_remove_btn"
                                      onClick={() =>
                                        removeFromCart(item.menu_id)
                                      }
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </motion.tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                    <div class="row justify-content-end table_1_row">
                      <motion.table
                        class="table cart_table_1"
                        initial={{ y: "100vh" }}
                        animate={{ y: 0 }}
                        transition={{
                          delay: 0.2,
                          duration: 0.5,
                          stiffness: 120,
                          type: "spring",
                        }}
                      >
                        <tr>
                          <td>
                            <div class="switch-button">
                              <input
                                class="switch-button-checkbox"
                                type="checkbox"
                                id="d_checkbox"
                                onClick={dilivaryCharge}
                              ></input>
                              <label class="switch-button-label" for="">
                                <span class="switch-button-label-span">
                                  Takeout
                                </span>
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              type="text"
                              class="form-control promo_code_input"
                              placeholder="Enter Promo Code"
                              value={coupenValue}
                              onChange={(e) => setCoupenValue(e.target.value)}
                            />
                            {!loading && (
                              <button
                                class="btn promo_code_btn"
                                onClick={applyCopen}
                              >
                                Apply
                              </button>
                            )}
                            {loading && (
                              <button
                                className="btn promo_code_btn"
                                type="button"
                                disabled
                              >
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>{" "}
                                Applying Coupen...
                              </button>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex justify-content-between">
                              <div class="Checkout_txt">Items Price</div>
                              <div class="Checkout_price">
                                <input
                                  type="text"
                                  class="form-control text-end cart_input"
                                  id="price_total"
                                  value={totalPrice}
                                  readonly
                                  style={{
                                    width: "100%",
                                    float: "right",
                                    marginRight: "-5px",
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex justify-content-between">
                              <div class="Checkout_txt">Delivery Fee</div>
                              <div class="Checkout_price">
                                {deliveryCharge && deliveryCharge ? (
                                  <>
                                    <input
                                      type="text"
                                      class="form-control text-end cart_input"
                                      id="d_input"
                                      value="99.00"
                                      readonly
                                      style={{
                                        width: "100%",
                                        float: "right",
                                        marginRight: "-5px",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="text"
                                      class="form-control text-end cart_input"
                                      id="d_input"
                                      value="0.00"
                                      readonly
                                      style={{
                                        width: "100%",
                                        float: "right",
                                        marginRight: "-5px",
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex justify-content-between">
                              <div class="Checkout_txt">Discount</div>
                              <div class="Checkout_price">
                                <input
                                  type="text"
                                  class="form-control text-end cart_input"
                                  id="discount"
                                  value={preDiscount}
                                  style={{
                                    width: "100%",
                                    float: "right",
                                    marginRight: "-5px",
                                  }}
                                  readonly
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ borderBottom: "2px dashed #604128 " }}>
                            <div class="d-flex justify-content-between">
                              <div class="Checkout_txt">Coupon Discount</div>
                              <div class="Checkout_price">
                                {discount && discount ? (
                                  <>
                                    <input
                                      type="text"
                                      class="form-control text-end cart_input"
                                      id="coupon_discount"
                                      value={-discount}
                                      style={{
                                        width: "100%",
                                        float: "right",
                                        marginRight: "-5px",
                                      }}
                                      readonly
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="text"
                                      class="form-control text-end cart_input"
                                      id="coupon_discount"
                                      value="0.00"
                                      style={{
                                        width: "100%",
                                        float: "right",
                                        marginRight: "-5px",
                                      }}
                                      readonly
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex justify-content-between">
                              <div
                                class="Checkout_txt"
                                style={{ fontWeight: "bold" }}
                              >
                                Total Amount{" "}
                              </div>
                              <div class="Checkout_price">
                                {totalAmountPay && totalAmountPay ? (
                                  <>
                                    <input
                                      type="text"
                                      class="form-control text-end cart_input"
                                      id="Grand_total"
                                      value={
                                        totalAmountPay && deliveryCharge
                                          ? Number(totalAmountPay) + 99
                                          : totalAmountPay
                                      }
                                      readonly
                                      style={{
                                        fontWeight: "bold",
                                        width: "100%",
                                        float: "right",
                                        marginRight: "-5px",
                                      }}
                                      
                                  
                                    />
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="text"
                                      class="form-control text-end cart_input"
                                      id="Grand_total"
                                      value={
                                        totalPrice && deliveryCharge
                                          ? Number(totalPrice) + 99
                                          : totalPrice
                                      }
                                      readonly
                                      style={{
                                        fontWeight: "bold",
                                        width: "100%",
                                        float: "right",
                                        marginRight: "-5px",
                                      }}
                                    
                                   
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                        {localStorageData ? (
                          <>
                            <tr>
                              <td>
                                <div
                                  class="card"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <div class="card-body">
                                    <h6 class="card-title">
                                      <img
                                        src={Asset84}
                                        alt="img"
                                        style={{ height: "25px" }}
                                      ></img>{" "}
                                      Selected Address
                                    </h6>
                                    <p
                                      class="card-text"
                                      style={{
                                        fontSize: "14px",
                                        margin: "8px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#7D453D",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Address :
                                      </span>{" "}
                                      {retrivedData && retrivedData[0]}
                                    </p>
                                    <p
                                      class="card-text"
                                      style={{
                                        fontSize: "12px",
                                        margin: "8px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#7D453D",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        City:
                                      </span>{" "}
                                      {retrivedData && retrivedData[4]}
                                    </p>
                                    <p
                                      class="card-text"
                                      style={{
                                        fontSize: "12px",
                                        margin: "8px",
                                      }}
                                    >
                                      {" "}
                                      <span
                                        style={{
                                          color: "#7D453D",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        State:
                                      </span>{" "}
                                      {retrivedData && retrivedData[1]}
                                    </p>
                                    <p
                                      class="card-text"
                                      style={{
                                        fontSize: "12px",
                                        margin: "8px",
                                      }}
                                    >
                                      {" "}
                                      <span
                                        style={{
                                          color: "#7D453D",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Mobile Number:
                                      </span>{" "}
                                      {retrivedData && retrivedData[5]}
                                    </p>
                                    <p
                                      class="card-text"
                                      style={{
                                        fontSize: "12px",
                                        margin: "8px",
                                      }}
                                    >
                                      {" "}
                                      <span
                                        style={{
                                          color: "#7D453D",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Pincode:
                                      </span>{" "}
                                      {retrivedData && retrivedData[2]}
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}

                        <tr>
                          <td>
                            <Link
                              class="btn Checkout_btn"
                              to={`/home/${user_id}/mycart/add_address`}
                            >
                              {!localStorageData
                                ? "Select Address"
                                : "Choose New Address"}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {!localStorageData ? (
                              <button
                                class="btn Checkout_btn"
                                onClick={selectAddress}
                              >
                                Proceed Checkout
                              </button>
                            ) : (
                              <button
                                class="btn Checkout_btn"
                                onClick={() => setPaymentModal(true)}
                              >
                                Proceed Checkout
                              </button>
                            )}
                          </td>
                        </tr>
                      </motion.table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {paymentModal === true ? (
            <PaymentModal hide={() => setPaymentModal(false)} />
          ) : (
            ""
          )}
          <Footer />
        </>
      )}
    </>
  );
}

export default MyCart;
