import React from "react";
import { Link } from "react-router-dom";
import Asset16 from "../images/Asset 16.png";
import Asset17 from "../images/Asset 17.png";
import Asset9 from "../images/Asset 9.png";
import { motion } from "framer-motion";

function MenuBar() {
  return (
    <>
      <div>
        <div className="menubar">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-flex">
                <img src={Asset16} className="mob_icon" alt="img" />
                <div className="menubar_txt1">+91 989290186</div>
                <img src={Asset17} className="mail_icon" alt="img" />
                <div className="menubar_txt1">info@chakkimart.in</div>
              </div>
              <div
                className="col-md-6 d-flex"
                id="menubar_col"
                
              >
                <Link to={"/home/coupons"} className="menubar_txt">
                  My Coupons
                </Link>
                <div className="menubar_vb"></div>
                <Link to={"/home/notification"} className="menubar_txt">
                  My Notifications
                </Link>
                <div className="menubar_vb"></div>
                <Link to={"/home/my_orders"} className="menubar_txt">
                  My Orders
                </Link>
                <div className="menubar_vb"></div>
                <Link to={"/home/myfavorites"} className="menubar_txt">
                <i class="fa-solid fa-heart likes text-danger" style={{fontSize: "14px"}}></i>   My Favorites 
                </Link>
                {/* <div className="menubar_vb"></div>
                <a href="#" className="menubar_txt">
                  Location/City
                </a>
                <img src={Asset9} className="location_icon" alt="img" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuBar;
