import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import MenuBar from "../Home/MenuBar";
import axios from "axios";
import Loader from "../Home/Loader/Loader";
import { useParams, useNavigate } from "react-router-dom";
import CancelReasonModal from "./CancelReasonModal";
import CancelledModal from "./CancelledModal";
// import { motion } from "framer-motion";
import Asset95 from "../images/Asset95.png";

function OrderCancelPage() {
  let user_id = localStorage.getItem("user_id");
  let order_id = localStorage.getItem("order_id");
  //For Active Buttons

  const [activeButton, setActiveButton] = useState("Two");

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderInsideData, setOrderInsideData] = useState([]);
  const [cancelReasonModal, setCancelReasonModal] = useState(false);
  const [itemWillCancelled, setItemWillCancelled] = useState(false);
  const [cancelledModal, setCancelledModal] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [nullMessage, setNullMessage] = useState(false);
  const [deliveryPage, setDeliveryPage] = useState(false);
  const [popularData, setPopularData] = useState("");

  //For Active Passive Buttons
  const navigate = useNavigate();

  const clickedButtonHandler = (e) => {
    // console.log(e.target);
    const { name } = e.target;
    setActiveButton("One");
    navigate("/home/my_orders");
    console.log(activeButton);
  };

  //Get Orders
  const getDataByID = async () => {
    setLoading(true);
    // setInterval(() => {
    //   console.log("hello")
    // }, 1000)

    // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
    const url = `https://chakkimart.in/admin/UserApi/orders?user_id=${user_id}`;

    axios
      .get(url)
      .then((response) => {
        console.log(response);
        if (response.data.message !== "Orders Found") {
          setNullMessage(true);
          setLoading(false);
          setDeliveryPage(false);
        } else {
          setLoading(false);
          setDeliveryPage(true);
          setOrderData(response.data.orders);
        }
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDataByID();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MenuBar />
          <Header />

          {nullMessage && (
            <>
              <div>
                <div
                  className="text-center p-3"
                  style={{ backgroundColor: "#F7F4F0" }}
                >
                  <img
                    src={Asset95}
                    alt="img"
                    style={{ width: "200px", height: "200p" }}
                  ></img>
                </div>
              </div>
            </>
          )}

          {deliveryPage && (
            <>
              <div
                className="text-center mb-2"
                initial={{ y: -300 }}
                animate={{ y: 0 }}
                transition={{
                  delay: 0.2,
                  duration: 0.5,
                  stiffness: 120,
                  type: "spring",
                }}
              >
                <button
                  className={
                    activeButton === "One"
                      ? "btn login_btn100"
                      : "btn login_btn"
                  }
                  onClick={clickedButtonHandler}
                >
                  Running
                </button>
                <button className="btn login_btn100 mx-2">History</button>
              </div>

              {orderData &&
                orderData.map((item, i) => {
                  return (
                    <>
                    
                      {item.order_details.status === "Cancelled By User" && (
                        <>
                          <div
                            class="card order-history55 p-3"
                            style={{ backgroundColor: "#F7F4F0" }}
                            key={i}
                            initial={{ x: "100vw" }}
                            animate={{ x: 0 }}
                            transition={{
                              delay: 0.2,
                              duration: 0.5,
                              stiffness: 120,
                              type: "spring",
                            }}
                          >
                            <div class="card-body card_delivery">
                              {" "}
                              <h6 class="card-title55 title55">
                                Ordered On {item.order_details.order_date}{" "}
                                {item.order_details.order_time}
                                <span class="status55">
                                  Status: {item.order_details.status}
                                </span>
                              </h6>
                              {item.order_items &&
                                item.order_items.map((items, index) => {
                                  // console.log(items);
                                  return (
                                    <>
                                      <div class="row disc55" key={index}>
                                        <div class="col-3 ">
                                          <img
                                            src={`https://chakkimart.in/admin/${items.item_image}`}
                                            alt="salman"
                                            class="prod-img55"
                                          />
                                        </div>
                                        <div class="col-9 order-disc55">
                                          {/* <p>
                                            {items.category}
                                            <br />
                                            {items.item_name}
                                            <br />
                                          </p>
                                          <h6>
                                            {items.item_quantity} x{" "}
                                            {items.item_price}
                                          </h6> */}

                                          <p>
                                            {items.category}
                                            <br />
                                            {items.item_name}
                                            <br />
                                          </p>

                                          <div className="col-md-4">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <div>
                                                Quantity:


                                              </div>
                                              <div style={{ fontSize: "16px" }}>
                                                {items.item_quantity} x{" "}
                                                {items.item_price}
                                              </div>
                                            </div>


                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <div> Total Price:{" "}</div>
                                              <div style={{ fontSize: "16px" }}>
                                                {items.total_price_after_discount}{" "}
                                              </div>
                                            </div>
                                          </div>

                                        </div>



                                      </div>
                                    </>
                                  );
                                })}
                              <div class="row detail55">
                                <div class="col-4">
                                  <p>
                                    Order ID:{" "}
                                    <span class="order-span55">
                                      {item.order_details.order_id}
                                    </span>{" "}
                                  </p>
                                </div>
                                <div class="col-3 order-25">
                                  <p>
                                    Total Amount{" "}
                                    <span class="order-span55">
                                      {item.order_details.total_amount}
                                    </span>{" "}
                                  </p>
                                </div>
                                <div class="col-5 text-end order-3">
                                  <p>
                                    Payment:{" "}
                                    <span class="order-span55">
                                      {item.order_details.payment_type}
                                    </span>
                                    {" "}
                                  </p>
                                </div>
                              </div>
                              <div class="div shipping55">
                                <p>Shipping Address</p>
                                <p>
                                  Name:{" "}
                                  <span class="addr-15">
                                    {item.order_details.username}
                                  </span>
                                </p>
                                <p>
                                  Mobile No:{" "}
                                  <span class="addr-25">
                                    {item.order_details.user_mobile}
                                  </span>
                                </p>
                                <p>
                                  Address:{" "}
                                  <span class="addr-35">
                                    {item.order_details.address},{""}
                                    {item.order_details.city},{" "}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}


            </>
          )}

          {cancelReasonModal === true ? (
            <CancelReasonModal
              hide={() => setCancelReasonModal(false)}
              time={popularData[0]}
              id={popularData[2]}
            />
          ) : (
            ""
          )}
          {cancelledModal === true ? (
            <CancelledModal
              hide={() => setCancelledModal(false)}
              time={errorData[2]}
              id={errorData[3]}
            />
          ) : (
            ""
          )}
          <Footer />
        </>
      )}
    </div>
  );
}

export default OrderCancelPage;
